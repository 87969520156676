<template>
  <Toast />
  <div>
    <Dialog 
      header="Header"
      v-model:visible="displayModal"
      :style="{width: '25vw'}"
      :modal="true"
    >
      <p class="p-m-0">NEW TRAINER</p>
      <InputText v-model="newTrainer.name" placeholder="Fullname"></InputText>
      <InputText v-model="newTrainer.title" placeholder="Title"></InputText>
      <Textarea v-model="newTrainer.biography" placeholder="Bio" :autoResize="true"></Textarea>

    <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
        <Button label="Save" icon="pi pi-check" @click="saveTrainer" autofocus />
    </template>
    </Dialog>
    <div class="trainer-profile-container">
      <div class="trainers-title-header">Trainers</div>
      <div class='trainers-picklist-container'>
        <Dropdown
            style="width:250px;height:25px;text-align:left;"
            :options="trainers"
            optionLabel="name"
            placeholder="Select a Trainer"
            v-model="profile"
        >
        <template #value="slotProps">
            <div v-if="slotProps.value">
                <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
                {{slotProps.placeholder}}
            </span>
          </template>

          <template #option="slotProps">
              <div>
                  <div>{{slotProps.option.id}} : {{slotProps.option.name}}, {{slotProps.option.title}}</div>
              </div>
          </template>
        </Dropdown>&nbsp;
        <Button
          class="p-button-success p-button-raised p-button-rounded p-button-lg"
          icon="pi pi-plus"
          @click="createTrainer()"
        />

      </div>
      <div class="trainers-assigned-container" v-if="eventTrainers">
        <span v-for="(trainer, index) in eventTrainers" :key="index">
          <img class="assigned-trainer-profile" 
            :tooltip="trainer.name"
            @click="removeTrainerFromEvent(trainer)" 
            :src="baseURL + trainerImagesPath + trainer.imageFilename">
        </span>
      </div>
      <div v-if="!profile.imageFilename">No Trainer selected!</div>
      <div class="profile-header" v-if="profile.imageFilename">
        <div class="profile-metadata-container">
          <span class="row-content-title">Name:</span> <span class="row-content">{{ profile.name }}</span><br>
          <span class="row-content-title">Title:</span> <span class="row-content">{{ profile.title }}</span><br>
          <span class="row-content-title">Location:</span> <span class="row-content">{{ profile.home }}</span><br>
        </div>
        <div class="profile-image-container">
          <img class="profile-image" :src="baseURL + trainerImagesPath + profile.imageFilename">
        </div>
      </div>
      <div class="row-content profile-bio">
        <span class="row-content-title" v-if="profile.imageFilename">Bio: </span> 
        <span v-html="profile.biography" />
      </div>
      <div class="button-bar" v-if="profile.imageFilename">
        <Button
          class="p-button-raised p-button-rounded p-button-lg"
          label="Add to Event"
          icon="pi pi-user"
          @click="addTrainerToEvent(profile)"
        />
        <Button
          class="p-button-raised p-button-rounded p-button-lg p-button-warning"
          label="Update Trainer"
          icon="pi pi-user-edit"
          @click="updateTrainer(profile)"
        />
        <Button
          class="p-button-raised p-button-rounded p-button-lg p-button-danger"
          label="Delete Trainer"
          icon="pi pi-user-minus"
          @click="deleteTrainer(profile)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

// Schema 
import Trainer from '../schemas/Trainer.js'

// API
import TrainerServices from '../services/TrainerServices.js'

// Primevue
import { useToast } from 'primevue/usetoast'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'

export default {
  name: "Train",
  components: {
    Dropdown,
    Button,
    Dialog,
    InputText,
    Textarea
  },
  setup() {
    const toast = useToast()
    const baseURL = process.env.VUE_APP_API_SERVER
    const trainerImagesPath = process.env.VUE_APP_TRAINER_IMAGES
    const trainers = ref(new Trainer())
    const profile = ref(new Trainer())
    const eventTrainers = ref([])
    const displayModal = ref(false)
    const newTrainer = ref(new Trainer())

    onMounted(() => {
      TrainerServices.getTrainers()
      .then((response) => {
        trainers.value = response.data
        toast.add({severity:'success', summary: 'Success', detail: "Received Trainers from DB", life: 5000})
      })
      .catch(() => {
        toast.add({severity:'error', summary: 'Error', detail: "Failed to retrieve Trainers from DB", life: 5000})
      })
    })

    const addTrainerToEvent = (trainer) => {
      eventTrainers.value.push(trainer)
      trainers.value.splice(trainers.value.indexOf(trainer),1)
      profile.value = new Trainer()
    }

    const removeTrainerFromEvent = (trainer) => {
      eventTrainers.value.splice(eventTrainers.value.indexOf(trainer),1)
      trainers.value.push(trainer)
    }

    const createTrainer = () => {
      displayModal.value = true
    }

    const closeModal = () => {
      newTrainer.value = new Trainer()
      displayModal.value = false
    }

    const saveTrainer = () => {
      closeModal()
    }

    return { 
      toast,
      baseURL,
      trainerImagesPath,
      trainers,
      newTrainer,
      profile,
      eventTrainers,
      displayModal,
      addTrainerToEvent,
      removeTrainerFromEvent,
      createTrainer,
      closeModal,
      saveTrainer
    }
  }
}
</script>

<style scoped>
.title {
  display: flex;
  font-size: 15px;
  font-weight: 800;
  width: 20%;  
}
.trainers-title-header {
  min-height: 75px;
  background-color:lightgrey;
  border-top-left-radius: 24.2px;
  border-top-right-radius: 24.2px;
  font-size: 4em;
  font-weight: bold;
  
}
.trainers-picklist-container {
  display: flex;
  flex-direction: column nowrap;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightblue;
}
.trainers-assigned-container {
  background-color: lightgreen;
}
.trainer-profile-container {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: grey;
  border-width: 1px;
  border-radius: 25px;
  margin: 25px;
  width: 500px;
}
.profile-header {
  display: flex;
  padding-top: 15px;
  flex-direction: column nowrap;
}
.profile-metadata-container {
  text-align: left;
  padding-left: 15px;
  width: 80%;
  
}
.profile-image-container {
  text-align: right;
  width: 20%;
  padding-right: 15px;
}
.profile-image {
  max-width: 100%;
  min-width: 100%;
  margin: 5px;
  border-color: Green;
  border-style: solid;
  border-width: 2px;
  border-radius: 50px;
}
.assigned-trainer-profile {
  max-width: 10%;
  min-width: 10%;
  margin: 5px;
  border-color: Green;
  border-style: solid;
  border-width: 2px;
  border-radius: 50px;
}
.profile-bio {
  padding-top: 25px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.row-content {
  text-align: justify;
  font-size: 12px;
  padding-left: 15px;
}
.row-content-title {
  font-weight: 800;
}
.button-bar {
  display: flex;
  flex-direction: column nowrap;
  justify-content: space-around;
  padding-bottom: 25px;
}
</style>