export default class Trainer {
  constructor(
    id,
    guid,
    eventId,
    name,
    title,
    home,
    biography,
    imageFilename

  ) {
    this.id = id,
    this.guid = guid,
    this.eventId = eventId,
    this.name = name,
    this.title = title,
    this.home = home,
    this.biography = biography,
    this.imageFilename = imageFilename
  }
}